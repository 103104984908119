$zoom: 1.44;

$basic-font: 'OpenSans', Helvetica, Arial, sans-serif;
$secondary-font: 'ProximaNova-Black', sans-serif;
$secondary-font--bold: 'ProximaNova-Bold', sans-serif;
$secondary-font--extrabold: 'ProximaNova-ExtraBold', sans-serif;

$white:#FFFFFF;
$black: #000000;
$red: #FC0191;
$slider-gray: #414045;
$sub-title-gray: #65656A;
$background-footer: #646262;
$footer-gray: #909095;