@import 'styles/variables';
@import 'styles/extensions';

.main-title {
  position: relative;
  background: url('../../../../../asset/resource/gen-hero-bg-rotated.png');
  background-repeat: no-repeat;
  background-position: right;
  background-size: 90%;
  box-shadow: inset 0 -20px 10px -8px #000;

  @media (min-width: 2560px) {
    background-size: 78vw;
  }

  @media screen and (max-width: 1240px) {
    background-position: right 35vw;  
  }

  @media screen and (max-width: 767px) {
    background-position: 100% 100vw;
    background-size: 160vw;
  }

  @media screen and (min-width: 580px) and (max-width: 767px) {
    background-size: 142vw;
  }


  &.safari {
    background-position: 0 50%;
    background-size: 90vw; 
  
    @media (min-width: 2560px) {
      background-size: 80vw;
    }

    @media screen and (max-width: 767px) {
      background-size: 0;
    }
  }
  
  &__wrapper {
    @extend %wrapper;
    padding-top: 180px;

    @media (min-width: 2560px) {
      max-width: calc(1280px * #{$zoom});
      padding-top: calc(200px * #{$zoom});
    }
  }

  h1 {
    position: relative;
    z-index: 100;
    padding-bottom: 40px;
    color: $white;
    font-weight: normal;
    font-size: 96px;
    font-family: $secondary-font;
    line-height: 104px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: uppercase;

    span { 
      color: $red;  
         
      @media screen and (max-width: 450px) {
        font-size: 8.7vw;
        line-height: 8.7vw;
      }        
    }

    @media (min-width: 2560px) {
      font-size: calc(96px * #{$zoom});
      line-height: calc(104px * #{$zoom});
    }

    @media screen and (max-width: 1200px) {
      font-size: 7vw;
      line-height: 7.5vw;
    }

    @media screen and (max-width: 768px) {
      padding-bottom: 150px;
      font-size: 10vw;
      line-height: 10.5vw;
    }

    @media screen and (max-width: 450px) {
      padding-bottom: 40px;
      font-size: 13vw;
      line-height: 10.5vw;
    }
  }

  &__heading {
    position: relative;
    z-index: 0;
    display: flex;
    justify-content: space-between;
    height: 100%;

    @media screen and (max-width: 767px) {
      flex-direction: column-reverse;
    }
  }

  &__heading-content {
    flex-basis: 50%;
    max-width: 565px;
    margin-top: 60px;

    @media (min-width: 2560px) {
      max-width: calc(600px * #{$zoom});
    }
  }
  
  &__description {
    position: relative;
    font-weight: 600;
    font-size: 20px;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;

    &:not(:last-child) {
      padding-bottom: 50px;
    }

    @media (min-width: 2560px) {
      font-size: calc(20px * #{$zoom});
      line-height: calc(24px * #{$zoom});
    }

    @media screen and (max-width: 450px) {
      font-size: 18px;
      line-height: 24px;
    }

    &::before {
      position: absolute;
      top: -20px;
      left: 0;
      width: 0;
      height: 2px;
      background: $white;
      content: '';
      transition: all 0.3s ease-in-out;
    }

    &.visible {
      &::before {
        width: 125px;
      }
    }

    &_colored-text
    &_link {
      color: $red;
      font-size: 22px;

      @media (min-width: 2560px) {
        max-width: calc(600px * #{$zoom});
        font-size: calc(20px * #{$zoom});
        line-height: calc(24px * #{$zoom});
      }
  
      @media screen and (max-width: 450px) {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }

  &__inview {
    flex-basis: 45%;
    margin-top: -23%;
    margin-right: -10%;

    @media (min-width: 2560px) {
      margin-top: -23%;
      margin-right: -10vw;
    }

    @media screen and (max-width: 1080px) {
      margin-top: initial;
    }

    @media screen and (max-width: 767px) {
      margin-right: 0;
    }
  }

  &__hiro-content {
    position: relative;

    img {
      width: 100%;
    }
  }

  &__cta {
    @extend %button-colored;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 376px;
    height: 100%;
    min-height: 48px;
    margin: 20px 0 50px;
    padding: 12px;
    color: $white;
    font-size: 16px;
    font-family: $secondary-font--extrabold;
    line-height: 1.3;
    background: linear-gradient(90deg, $red 0%, $red 100%);

    @media screen and (min-width: 2560px) {
      max-width: calc(376px * #{$zoom});
      margin: calc(20px * #{$zoom}) 0 calc(50px * #{$zoom});
      padding: calc(12px * #{$zoom});
      font-size: calc(16px * #{$zoom});
    }

    @media screen and (max-width: 475px) {
      max-width: 100%;
      margin-bottom: 0;
    }

    &:hover {
      color: $red;
      background: linear-gradient(90deg, $white 0%, $white 100%);
    }
  }

  &__video-content {
    width: 90%;
    margin-bottom: -5px;

    &_with-bg {
      @media screen and (max-width: 820px) {
        background: url('../../../../../asset/resource/hero-bg-mobile.png');
        background-repeat: no-repeat;
        background-position: bottom center;
        background-size: 150%;
      }
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      box-shadow: inset 0 -20px 10px -8px #000;
    }
  }
}