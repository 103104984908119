@font-face {
  font-weight: 400;
  font-family: 'ProximaNova-Regular';
  src: url('https://info.softserveinc.com/hubfs/common-demo-assets/fonts/ProximaNova/ProximaNova-Regular.ttf');
}

@font-face {
  font-weight: 700;
  font-family: 'ProximaNova-Bold';
  src: url('https://info.softserveinc.com/hubfs/common-demo-assets/fonts/ProximaNova/ProximaNova-Bold.ttf');
}

@font-face {
  font-weight: 800;
  font-family: 'ProximaNova-ExtraBold';
  src: url('https://info.softserveinc.com/hubfs/common-demo-assets/fonts/ProximaNova/ProximaNova-Extrabld.ttf');
}
@font-face {
  font-weight: 900;
  font-family: 'ProximaNova-Black';
  src: url('https://info.softserveinc.com/hubfs/common-demo-assets/fonts/ProximaNova/ProximaNova-Black.ttf');
}

@font-face{
  font-weight: normal;
  font-family: 'OpenSans';
  src: url('https://info.softserveinc.com/hubfs/fonts/OpenSans-Regular.ttf');
}