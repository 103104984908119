@import 'styles/variables';

.form-input {
  margin-bottom: 15px;

  @media (min-width: 2560px) {
    margin-bottom: calc(15px * #{$zoom});
  }

  &__error {
    margin-top: 10px;
    margin-bottom: 20px;
    color: rgb(235, 76, 76);
    font-weight: normal;
    font-size: 16px;
    font-family: $secondary-font;
    letter-spacing: 0.4px;
  }

  &_has-error {
    .form-input__text,
    .form-input__textarea,
    .form-input__select .form-input__select-wrap {
      border-color: rgb(235, 76, 76);
    }

    .form-input__checkbox-label:before,
    .form-input__radio-label:before {
      border-color: rgb(235, 76, 76);
    }
  }

  &__label {
    display: inline-block;
    margin: 0;
    padding: 0;
    color: $white;
    font-size: 16px;
    font-family: $basic-font;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 5px;

      @media (min-width: 2560px) {
        margin-bottom: calc(5px * #{$zoom});
      }
    }

    @media (min-width: 2560px) {
      font-size: calc(16px * #{$zoom});
    }
  }

  &__text,
  &__textarea {
    display: block;
    width: 100%;
    margin: 0;
    padding: 10px 18px;
    color: $white;
    font-weight: normal;
    font-size: 16px;
    font-family: $basic-font;
    background: transparent;
    border: 2px solid $white;
    border-radius: 0;
    outline: none;

    @media (min-width: 2560px) {
      padding: calc(10px * #{$zoom}) calc(18px * #{$zoom});
      font-size: calc(16px * #{$zoom});
      border-width: calc(2px * #{$zoom});
    }

    &::-webkit-input-placeholder {
      text-transform: uppercase;
    }

    &::-moz-placeholder {
      text-transform: uppercase;
    }

    &:-moz-placeholder {
      text-transform: uppercase;
    }

    &:-ms-input-placeholder {
      text-transform: uppercase;
    }
  }

  &__text {
    height: 56px;

    @media (min-width: 2560px) {
      height: calc(56px * #{$zoom});
    }
  }

  &__textarea {
    min-height: 204px;
    padding: 20px 18px;
    text-transform: none;
    resize: vertical;

    @media (min-width: 2560px) {
      min-height: calc(204px * #{$zoom});
      padding: calc(20px * #{$zoom}) calc(18px * #{$zoom});
    }

    &::-webkit-input-placeholder {
      text-transform: uppercase;
    }

    &::-moz-placeholder {
      text-transform: uppercase;
    }

    &:-moz-placeholder {
      text-transform: uppercase;
    }

    &:-ms-input-placeholder {
      text-transform: uppercase;
    }
  }

  &__select {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0 20px 0 0;
    color: $black;
    font-weight: normal;
    font-size: 16px;
    font-family: $secondary-font;
    text-transform: uppercase;
    background: transparent;
    background-image: none;
    border: none;
    border-radius: 0;
    outline: none;
    box-shadow: none;
    -webkit-appearance: none;

    @media (min-width: 2560px) {
      padding-right: calc(20px * #{$zoom});
      font-size: calc(16px * #{$zoom});
    }

    > option {
      text-transform: none;
    }

    &-wrap {
      position: relative;
      height: 58px;
      padding: 0 18px 0 12px;
      border: 2px solid $black;
      border-radius: 0;
      outline: none;
      box-shadow: none;

      @media (min-width: 2560px) {
        height: calc(58px * #{$zoom});
        padding: 0 calc(18px * #{$zoom}) 0 calc(12px * #{$zoom});
        border-width: calc(2px * #{$zoom});
      }

      &:after {
        position: absolute;
        top: calc(50% - 3px);
        right: 20px;
        content: '';
        border-width: 7px 6px 0 6px;
        border-style: solid;
        border-color: #000000 transparent transparent transparent;
      }
    }
  }

  &__checkbox,
  &__radio {
    &:not(:last-child) {
      margin-bottom: 6px;
    }

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      opacity: 0;
    }

    label {
      position: relative;
      display: inline-block;
      margin: 0;
      padding: 0;
      cursor: pointer;
    }

    &-label {
      position: relative;
      min-height: 20px;
      padding-left: 25px;
      color: $black;
      font-size: 16px;
      font-family: $basic-font;
      line-height: 1.37em;

      @media (min-width: 2560px) {
        min-height: calc(20px * #{$zoom});
        padding-left: calc(25px * #{$zoom});
        font-size: calc(16px * #{$zoom});
      }

      a {
        color: inherit;

        &:hover {
          text-decoration: none;
        }
      }

      &:before {
        position: absolute;
        content: '';
        top: 10px;
        left: 0;
        width: 16px;
        height: 16px;
        font-size: 13px;
        line-height: 1;
        text-align: center;
        border: 2px solid $black;
        transform: translateY(-50%);

        @media (min-width: 2560px) {
          top: calc(10px * #{$zoom});
          width: calc(16px * #{$zoom});
          height: calc(16px * #{$zoom});
          font-size: calc(12px * #{$zoom});
          border-width: calc(2px * #{$zoom});
        }
      }
    }
  }

  &__checkbox {
    input:checked + &-label:before {
      background-color: $white;
      border-color: $white;
      content: '✓';
    }
  }

  &__radio {
    input:checked + &-label:after {
      position: absolute;
      top: 50%;
      left: 4px;
      content: '';
      width: 7px;
      height: 7px;
      background-color: $black;
      border-radius: 50%;
      transform: translateY(-50%);
    }

    &-label {
      &:before {
        width: 15px;
        height: 15px;
        border-radius: 50%;
      }
    }
  }
}