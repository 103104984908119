@import 'styles/variables';
@import 'styles/extensions';

.how-to-use {
  padding-top: 100px;
  background: $black;

  &__wrapper {
    @extend %wrapper;
    max-width: 1140px;
    padding-bottom: 90px;
  
    @media (min-width: 2560px) {
      max-width: calc(1140px * #{$zoom});
      padding-bottom: calc(90px * #{$zoom});
    }

    @media (max-width: 475px) {
      padding-bottom: 0;
    }
  }

  h2 {
    padding-right: 0;
    padding-bottom: 90px;
    padding-left: 0;
    color: $white;
    font-weight: 800;
    font-size: 60px;
    font-family: $secondary-font--extrabold;
    line-height: 64px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: uppercase;

    @media (min-width: 2560px) {
      padding-bottom: calc(90px * #{$zoom});
      font-size: calc(60px * #{$zoom});
      line-height: calc(64px * #{$zoom});
    }

    @media screen and (max-width: 767px) {
      font-size: 48px;
      line-height: 56px;
    }

    @media screen and (max-width: 450px) {
      padding-bottom: 40px;
      font-size: 32px;
      line-height: 36px;
    }

    span {
      color: $red;
    }
  }

  .sub-title {
    position: relative;
    display: inline-block;
    margin-bottom: 55px;
    color: $white;
    font-weight: 800;
    font-size: 32px;
    font-family: $secondary-font--extrabold;
    line-height: 36px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: uppercase;

    @media (min-width: 2560px) {
      font-size: calc(32px * #{$zoom});
      line-height: calc(36px * #{$zoom});
    }

    @media (max-width: 475px) {
      margin-bottom: 25px;
      font-size: 18px;
    }

    &::after {
      position: absolute;
      top: -5px;
      left: 0;
      width: 100%;
      height: 2px;
      background: $white;
      transition: width 0.3s;
      content: '';
    }
  }

  &__items-block {
    padding-bottom: 40px;

    @media (min-width: 2560px) {
      padding-bottom: calc(40px * #{$zoom});
    }
    @media (max-width: 475px) {
      padding-bottom: 0px;
    }

    h3 {
      padding-bottom: 65px;
      color: $white;
      font-weight: normal;
      font-size: 24px;
      font-family: $secondary-font;
      line-height: 125%;
      letter-spacing: 0px;
      text-align: left;
  
      @media (min-width: 2560px) {
        padding-bottom: calc(35px * #{$zoom});
        font-size: calc(24px * #{$zoom});
      }
    }
  }

  &__item {
    @media (max-width: 475px) {
      display: flex;
      column-gap: 20px;
    }
  }

  &__item-icons {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    column-gap: 40px;

    @media (max-width: 475px) {
      flex-direction: column;
      row-gap: 30px;
    }

    &::before {
      position: absolute;
      top: 50%;
      left: -200%;
      z-index: 0;
      width: 100%;
      height: 1px;
      background: $white;
      transform: translateY( -50%);
      transition: all 0.2s ease 1s;
      content: '';
    }

    &.visible {
      &::before {        
        left: 0;
        transition: all 0.2s ease 1s;

        @media (max-width: 475px) {
          left: 50%;
          width: 1px;
          height: 100%;
        }
      }
    }

    & > div {
      position: relative;
      z-index: 0;
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
    }

    img {
      width: 100%;
      max-width: 83px;

      @media screen and (min-width: 2560px) {
        max-width: calc(83px * #{$zoom});
      }

      @media (max-width: 475px) {
        min-width: 42px;
      }
    }
  }

  &__item-text {
    display: flex;
    justify-content: space-evenly;
    margin-top: 30px;
    color: $white;
    column-gap: 40px;

    @media (max-width: 475px) {
      flex-direction: column;
      row-gap: 30px;
      margin-top: 0;
    }

    & > div {
      flex: 1;
    }

    p {
      @media (min-width: 2560px) {
        font-size: calc(16px * #{$zoom});
      }
    }
  }

  &.try-it-out {
    .how-to-use__items-block-top-text,
    .how-to-use__items-block-bottom-text {
      max-width: 585px;
      color: $white;

      @media (min-width: 2560px) {
        max-width: calc(585px * 1.44);
        font-size: calc(16px * 1.44);
      }
    }

    .how-to-use__item {
      padding: 50px 0;
    }

    .how-to-use__item-icons > div {
      justify-content: flex-start;
    }

    .how-to-use__item-text > div {
      p {
        font-family: $secondary-font--extrabold;

        @media (min-width: 2560px) {
          font-size: calc(16px * 1.44);
        }
      }
    }
  }

  &__cta {
    @extend %button-colored;
    display: none;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 376px;
    height: 100%;
    min-height: 48px;
    margin: 70px auto 0;
    padding: 12px;
    color: $white;
    font-size: 16px;
    font-family: $secondary-font--extrabold;
    line-height: 1.3;
    background: linear-gradient(90deg, $red 0%, $red 100%);
    border: 2px solid $red;

    &:hover {
      color: $red;
      background: linear-gradient(90deg, $white 0%, $white 100%);
      border: 2px solid $red;
    }
  }
}