@import 'styles/variables';
@import 'styles/extensions';

.header {
  position: fixed;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 96px;
  background: $black;

  &__wrapper {
    @extend %wrapper;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__event-logo-wrapper {
    display: flex;
    gap: 20px;
    align-items: center;

    @media screen and (min-width: 2560px) {
      gap: calc(20px * #{$zoom});
    }
  }

  &__event-logo {
    display: inline-block;
    margin-top: -25px;
    margin-left: 14px;
    font-weight: 800;
    font-size: 16px;
    font-family: $secondary-font;
    line-height: 16px;
    text-transform: uppercase;
    cursor: pointer;

    @media (min-width: 2560px) {
      margin-top: calc(-25px * #{$zoom});
      margin-left: calc(14px * #{$zoom});
      font-size: calc(16px * #{$zoom});
      line-height: calc(16px * #{$zoom});
    }

    &:hover {
      color: $red;
    } 
  }

  &__logo {
    height: 20px;
    filter: invert(1);

    @media (min-width: 2560px) {
      height: calc(20px * #{$zoom});
    }
  }

  &__partner-logo,
  &__partner-link {
    max-height: 30px;

    @media screen and (min-width: 2560px) {
      max-height: calc(29px * #{$zoom});
    }
  }

  nav {
    display: flex;
  }

  &__left {
    display: flex;
    flex-direction: row;
    gap: 24px;

    @media screen and (max-width: 1240px) {
      flex-direction: column;
      gap: 6px;
    }
  }

  &__min-title {
    display: flex;
    align-items: flex-end;
    margin-bottom: 2px;
    color: #D1D1D1;
    font-weight: 800;
    font-size: 16px;
    font-family: $secondary-font--extrabold;
    letter-spacing: 0.2px;
    text-transform: uppercase;

    @media screen and (min-width: 2560px) {
      font-size: calc(16px * #{$zoom});
    }
  }

  &__navigation-link {
    position: relative;
    display: flex;
    align-items: center;
    padding: 6px 16px;
    color: $footer-gray;
    font-weight: 800;
    font-size: 16px;
    font-family: $secondary-font--extrabold;
    line-height: 0.9;
    text-align: center;
    text-transform: uppercase;
    transition: all 0.2s ease-in;

    &:not(:last-child) {
      margin-right: 32px;
    }

    &:hover {
      color: $white;
      text-align: center;
      background-color: $red;
      cursor: pointer;
      transition: all 0.2s ease-in;
    }

    @media (max-width: 1024px) {
      padding: 5px 10px;
      font-size: 1.5vw;
    }

    @media (max-width: 767px) {
      display: none;
    }

    @media (min-width: 2560px) {
      font-size: calc(16px * #{$zoom});
      line-height: calc(14px * #{$zoom});
    }

    &.active {
      color: $white;
      text-align: center;
      background-color: $red;
      transition: all 0.2s ease-in;
    }
  }

  &__button {
    position: relative;
    display: flex;
    width: 52px;
    height: 52px;
    margin: 0;
    padding: 0;
    background: transparent;
    border: transparent;
    border-left: 1px solid transparent;
    outline: none;
    cursor: pointer;
    transition: border 0.35s;

    @media (min-width: 2560px) {
      width: calc(50px * #{$zoom});
      height: calc(50px * #{$zoom});
      margin: calc(5px * #{$zoom}) 0;
    }

    &::before,
    &::after {
      position: absolute;
      top: 50%;
      right: 0;
      left: 0;
      width: 16px;
      height: 2px;
      margin: 0 auto;
      background-color: $white;
      box-shadow: 0 -6px 0 0 $white;
      transform: translateY(-50%);
      content: '';

      @media (min-width: 2560px) {
        width: calc(16px * #{$zoom});
        height: calc(2px * #{$zoom});
      }
    }

    &::after {
      right: -8px;
      left: -4px;
      width: 12px;
      box-shadow: 0 6px 0 0 $white;

      @media (min-width: 2560px) {
        right: calc(-8px * #{$zoom});
        left: calc(-4px * #{$zoom});
        width: calc(12px * #{$zoom});
      }
    }

    &_cross {
      &::before,
      &::after {
        width: 16px;
        box-shadow: 0 0 0 0 $white;
        transition: transform 0.2s 0.1s, box-shadow 0.1s, width 0.1s, left 0s 0.1s;

        @media (min-width: 2560px) {
          width: calc(16px * #{$zoom});
        }
      }

      &::before {
        background-color: $white;
        transform: translateY(-50%) rotate(45deg);
      }

      &::after {
        left: -8px;
        background-color: $white;
        transform: translateY(-50%) rotate(-45deg);
      }
    }
  }

  &__menu-navigation {
    position: fixed;
    top: 95px;
    left: 0;
    z-index: 10000;
    width: 100%;
    height: 100vh;
    backdrop-filter: blur(7px);
  }

  &__menu-navigation-links {
    position: absolute;
    z-index: 111;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    height: 65vh;
    margin: 0 auto 0;
    padding: 0 40px;
    background: $black;

    &_close {
      position: absolute;
      z-index: 111;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 100%;
      height: 65vh;
      margin: 0 auto 0;
      padding: 0 40px;
      background: $black;
      transform: translateY(-100%);
      transition: 0.3s all ease-in-out;
    }

    &_open {
      position: absolute;
      z-index: 111;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 100%;
      height: 50vh;
      margin: 0 auto 0;
      padding: 0 40px;
      background: $black;
      transform: translateY(0);
      transition: 0.3s all ease-in-out;
    }
  }

  &__top-navigation-link {
    position: relative;
    width: fit-content;
    font-weight: bold;

    &:hover {
      color: $red;
      cursor: pointer;
    }

    @include customLinkAnimation($red, 2px);
  }

  &__force-refresh {
    display: none;
    width: 80px;
    height: 80px;
    margin-right: 15px;
    margin-left: auto;
    background: transparent;
    border: none;
  }
}

.header__top-navigation-link-appear {
  transform: translatex(800px);
}

.header__top-navigation-link-appear-done {
  transform: translatex(0);
  transition: transform 1s ease, box-shadow 0.2s ease;
}